.login-left-side {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.heading {
    color: #fff !important;
    /* component-heading-large/3xl */
    font-family: "Inter";
    font-size: 60px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 100% !important;
}

%shared_sub_text {
    color: #fff !important;
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
}

.sub-text {
    @extend %shared_sub_text;
    font-weight: 700;
}

.sub-sub-text {
    @extend %shared_sub_text;
    font-weight: 400;
}

.login-container {
    font-family: "Inter" !important;
}

.form-heading {
    color: #171923;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
}

.form-sub-heading {
    color: #4A5568;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.form-sub-headin-link {
    color: #319795;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.login-container input {
    font-family: "Inter" !important;
}